import classNames from 'classnames';
import { FieldHelperProps, FieldInputProps, FieldMetaProps, FormikContextType, useField, useFormikContext } from 'formik';

export interface BaseFieldProps {
    name: string;
    label?: string;
    hint?: string;
    wrapperClassName?: string;
    required?: boolean;
    placeholder?: string;
    capitalize?: boolean;
    disabled?: boolean;
}

export interface BaseTextFieldProps extends BaseFieldProps {
    maxLength?: number;
    formControlClassName?: string;
}

interface InputFieldProps extends BaseFieldProps {
    renderElement: (
        formik: FormikContextType<any>,
        field: FieldInputProps<any>,
        meta: FieldMetaProps<any>,
        helpers: FieldHelperProps<any>
    ) => React.ReactElement;
}

export const InputField = ({ name, label, hint, renderElement, wrapperClassName, required, capitalize = true }: InputFieldProps) => {
    const formik = useFormikContext();
    const [field, meta, helpers] = useField(name);

    return (
        <div className={classNames('data-item-row', wrapperClassName)} onClick={(e) => e.stopPropagation()}>
            {label && (
                <label className={classNames('data-item-col data-item-col-title', { 'data-item-capitalize': capitalize })}>
                    {label}
                    {required && <span className="text-red"> *</span>}
                </label>
            )}
            {renderElement(formik, field, meta, helpers)}
            {hint && <span className="data-item-hint">{hint}</span>}
        </div>
    );
}
